var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{class:_vm.$i18n.locale === 'ar' ? 'rtl' : 'ltr'},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3 font-weight-bold mb-3 text-grey text-center"},[_vm._v(" "+_vm._s(_vm.ModulesCourses.courseTitle)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"just":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"text-lg-h5 font-weight-bold text-white py-1 px-8 text-no-wrap rounded-pill secondary-color"},[_vm._v(" "+_vm._s(_vm.$t("informationsTest"))+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('course-module',_vm._b({attrs:{"title":_vm.ModulesCourses.moduleTitle}},'course-module',{
              width: 'auto',
              textCenter: true,
              bold: true
            },false))],1)],1)],1),(!_vm.showAnswers)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.ModulesCourses.moduleQA),function(item,index){return _c('div',{key:index,staticClass:"w-100 text-right mt-10 pa-3"},[_c('div',{staticClass:"text-grey question d-flex align-center text-lg-h6"},[_c('h4',{staticClass:"mx-2"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(item.question.body.content)+" ")]),_c('div',{staticClass:"d-flex flex-column w-100"},[_c('v-radio-group',{attrs:{"disabled":!!_vm.answers[item.question.body.id] && _vm.showAnswers},model:{value:(_vm.answers[item.question.body.id]),callback:function ($$v) {_vm.$set(_vm.answers, item.question.body.id, $$v)},expression:"answers[item.question.body.id]"}},_vm._l((item.question.answers),function(answer,index){return _c('v-row',{key:index,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('course-module',_vm._b({attrs:{"border":_vm.answers[item.question.body.id] === answer.id &&
                      _vm.showAnswers
                        ? answer.answered == 'true'
                          ? '2px solid #0c0 !important'
                          : '2px solid #c00 !important'
                        : ''}},'course-module',{ title: answer.content, width: '100%' },false),[_c('div',{staticClass:"d-flex"},[(true)?_c('v-icon',{attrs:{"color":_vm.answers[item.question.body.id] === answer.id &&
                          _vm.showAnswers
                            ? answer.answered === 'true'
                              ? _vm.primaryColor
                              : _vm.RColor
                            : ''}},[_vm._v(" "+_vm._s(_vm.answers[item.question.body.id] === answer.id && _vm.showAnswers ? answer.answered === "true" ? _vm.answerIcon : _vm.closeIcon : "")+" ")]):_vm._e(),_c('v-radio',{attrs:{"color":"#00D4FF","value":answer.id}})],1)])],1)],1)}),1)],1)])}),0)],1):_vm._e(),(_vm.showAnswers)?_c('questions-results',{attrs:{"moduleQA":_vm.ModulesCourses.moduleQA,"showAnswers":_vm.showAnswers,"answers":_vm.answers}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-10 text-lg-h6 ma-5",attrs:{"color":"#00D4FF","disabled":this.numberOfAnswers != this.numberOfChoosenAns || _vm.disableButtons,"loading":_vm.loading},on:{"click":_vm.sendAnswersMethod}},[_vm._v(" "+_vm._s(_vm.$t("form.submit"))+" ")]),(_vm.showAnswers)?_c('router-link',{attrs:{"to":{ path: ("/courses/" + (this.$route.params.id)) }}},[_c('v-btn',{staticClass:"px-10 text-lg-h6 ma-5",attrs:{"color":_vm.darkColor,"dark":""}},[_vm._v(" "+_vm._s(_vm.$t("backToCoursePage"))+" ")])],1):_vm._e()],1),(_vm.showAnswers)?_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_c('v-icon',{attrs:{"color":_vm.primaryColor}},[_vm._v(_vm._s(_vm.answerIcon))]),_c('strong',[_vm._v("("+_vm._s(_vm.ModulesCourses.correctAnswersCount)+", "+_vm._s(_vm.numberOfAnswers)+")")]),_vm._v(" "+_vm._s(_vm.$t("correctAnswers"))+" ")],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }