<template>
  <v-container>
    <v-row :class="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
      <!-- title -->
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey text-center">
          {{ ModulesCourses.courseTitle }}
        </h1>
      </v-col>
      <!-- module info -->
      <v-col cols="12">
        <v-row just>
          <v-col cols="12" sm="4">
            <div class="d-flex">
              <h2
                class="text-lg-h5 font-weight-bold text-white py-1 px-8 text-no-wrap rounded-pill secondary-color"
              >
                {{ $t("informationsTest") }}
              </h2>
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="4">
            <course-module
              :title="ModulesCourses.moduleTitle"
              v-bind="{
                width: 'auto',
                textCenter: true,
                bold: true
              }"
            >
            </course-module>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12">
        <div class="py-2 px-5 mb-3 rounded-pill warning-color w-100">
          <span
            class="text-lg-h6 text-sm-caption font-weight-bold text-white w-100"
          >
            لديك عدد (4 محاولات) للإجابة على الأسئلة بين كل محاولة والأخرى فترة
            انتظار 15 ثانية وذلك حتى تتمكن من دراسة صحة الإجابة قبل الإرسال.
          </span>
        </div>
      </v-col> -->
      <!-- Q&A before submit -->
      <v-col v-if="!showAnswers" cols="12">
        <v-row>
          <div
            v-for="(item, index) in ModulesCourses.moduleQA"
            :key="index"
            class="w-100 text-right mt-10 pa-3"
          >
            <div class="text-grey question d-flex align-center text-lg-h6">
              <h4 class="mx-2">{{ index + 1 }}</h4>
              {{ item.question.body.content }}
            </div>
            <div class="d-flex flex-column w-100">
              <v-radio-group
                v-model="answers[item.question.body.id]"
                :disabled="!!answers[item.question.body.id] && showAnswers"
              >
                <!-- real -->
                <v-row
                  v-for="(answer, index) in item.question.answers"
                  :key="index"
                  dense
                >
                  <!-- answers container -->
                  <v-col cols="12" md="6">
                    <course-module
                      v-bind="{ title: answer.content, width: '100%' }"
                      :border="
                        answers[item.question.body.id] === answer.id &&
                        showAnswers
                          ? answer.answered == 'true'
                            ? '2px solid #0c0 !important'
                            : '2px solid #c00 !important'
                          : ''
                      "
                    >
                      <!-- answers items -->
                      <div class="d-flex">
                        <v-icon
                          v-if="true"
                          :color="
                            answers[item.question.body.id] === answer.id &&
                            showAnswers
                              ? answer.answered === 'true'
                                ? primaryColor
                                : RColor
                              : ''
                          "
                        >
                          {{
                            answers[item.question.body.id] === answer.id &&
                            showAnswers
                              ? answer.answered === "true"
                                ? answerIcon
                                : closeIcon
                              : ""
                          }}
                        </v-icon>
                        <v-radio color="#00D4FF" :value="answer.id" />
                      </div>
                    </course-module>
                  </v-col>
                </v-row>
              </v-radio-group>
            </div>
          </div>
        </v-row>
      </v-col>
      <!-- Q&A after submit -->
      <questions-results
        v-if="showAnswers"
        :moduleQA="ModulesCourses.moduleQA"
        :showAnswers="showAnswers"
        :answers="answers"
      ></questions-results>
      <!-- action buttons -->
      <v-col cols="12">
        <!-- <v-btn
          @click="saveAnswers"
          class="px-10 text-lg-h6 ma-5"
          color="#FFA847"
          :disabled="
            this.numberOfAnswers != this.numberOfChoosenAns || disableButtons
          "
        >
          {{ $t("form.save") }}
        </v-btn> -->
        <v-btn
          @click="sendAnswersMethod"
          class="px-10 text-lg-h6 ma-5"
          color="#00D4FF"
          :disabled="
            this.numberOfAnswers != this.numberOfChoosenAns || disableButtons
          "
          :loading="loading"
        >
          {{ $t("form.submit") }}
        </v-btn>
        <router-link
          v-if="showAnswers"
          :to="{ path: `/courses/${this.$route.params.id}` }"
        >
          <v-btn class="px-10 text-lg-h6 ma-5" :color="darkColor" dark>
            {{ $t("backToCoursePage") }}
          </v-btn>
        </router-link>
      </v-col>
      <!-- result -->
      <v-col v-if="showAnswers" cols="12">
        <h3>
          <v-icon :color="primaryColor">{{ answerIcon }}</v-icon
          ><strong
            >({{ ModulesCourses.correctAnswersCount }},
            {{ numberOfAnswers }})</strong
          >
          {{ $t("correctAnswers") }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CourseModule from "./Components/CourseModule";
import QuestionsResults from "./Components/QuestionsResults";

export default {
  name: "ModuleTest",

  components: {
    CourseModule,
    QuestionsResults
  },

  data() {
    return {
      loading: false,
      showAnswers: false,
      answers: [],
      disableButtons: false
    };
  },

  created() {
    this.getModuleQA(this.$route.params.moduleId);
    this.getCourseModules(this.$route.params.id);
    // if (localStorage.getItem("answers") !== null) {
    //   const answersSaved = JSON.parse(localStorage.getItem("answers"));
    //   this.answers = answersSaved;
    // }
  },

  methods: {
    ...mapActions("CourseArea", [
      "getCourseModules",
      "getModuleQA",
      "sendAnswers"
    ]),
    // save to localstorage
    saveAnswers() {
      // const answersToSaved = this.answers
      //   .filter((e) => e)
      //   .map((e, i) => ({
      //     qustion_id: this.ModulesCourses.moduleQA[i].question.body.id,
      //     answer_id: e
      //   }));
      // localStorage.setItem("answers", JSON.stringify(this.answers));
    },
    sendAnswersMethod() {
      this.loading = true;
      const answers = this.answers
        .map((e, i) => ({
          qustion_id: i,
          answer_id: e
        }))
        .filter((e) => e.answer_id != null);
      this.sendAnswers({
        module_id: Number(this.$route.params.moduleId),
        answers: answers
      })
        .then(() => {
          this.loading = false;
          this.showAnswers = true;
          this.disableButtons = true;
          // trigger showResult function to see correct answers
          this.showResult();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showResult() {
      let newModuleQA = this.ModulesCourses.moduleQA;
      // get only IDs from student answers
      let studentAnswersId = this.answers
        .map((e, i) => ({
          qustion_id: i,
          answer_id: e
        }))
        .filter((e) => e.answer_id != null)
        .map((e) => e.answer_id);
      // get all answers from questions
      let answered = newModuleQA.map((e) => e.question.answers);
      // loop through ModuleQA to change answered value to student value, so we know which answers he choose
      for (let i = 0; i < studentAnswersId.length; i++) {
        for (let j = 0; j < answered[i].length; j++) {
          if (studentAnswersId[i] === answered[i][j].id) {
            answered[i][j].answered = "true";
          } else answered[i][j].answered = "false";
        }
      }
      // get correct answers from state
      let correctAnswers = this.ModulesCourses.correctAnswers;
      // loop through ModulQA to add 'true' key, so we know student answers are true or not
      for (let i = 0; i < newModuleQA.length; i++) {
        for (let j = 0; j < correctAnswers.length; j++) {
          newModuleQA[i].question.body.id === correctAnswers[j].questionId &&
          correctAnswers[j].correct == 1
            ? (newModuleQA[i].question.true = "true")
            : "";
        }
      }
    }
  },

  computed: {
    ...mapState("CourseArea", ["ModulesCourses"]),
    numberOfAnswers() {
      return this.ModulesCourses.moduleQA.length;
    },
    numberOfChoosenAns() {
      return this.answers.filter((e) => e).length;
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 300%;
  color: #efeeef;
  font-weight: bold;
}
</style>
