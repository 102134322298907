<template>
  <div
    class="py-2 px-7 mb-3 text-no-wrap rounded-pill grey lighten-4 d-flex justify-space-between align-center"
    :style="{ width: width, border: border }"
  >
    <span
      class="grey--text text--darken-1 text-sm-body-1"
      :class="[
        bigText ? 'text-lg-h5' : 'text-lg-h6',
        textCenter ? 'w-100 text-center' : '',
        { 'font-weight-bold': bold }
      ]"
    >
      {{ title }}
    </span>
    <slot />
  </div>
</template>

<script>
export default {
  name: "CourseModule",

  props: {
    title: String,
    width: String,
    textCenter: Boolean,
    bold: Boolean,
    bigText: Boolean,
    border: String
  }
};
</script>

<style>
a:hover {
  text-decoration: none;
}
</style>
