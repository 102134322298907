var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.moduleQA),function(item,index){return _c('div',{key:index,staticClass:"w-100 text-right mt-10 pa-3"},[_c('div',{staticClass:"text-grey question d-flex align-center text-lg-h6"},[_c('h4',{staticClass:"mx-2"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(item.question.body.content)+" ")]),_c('div',{staticClass:"d-flex flex-column w-100"},[_c('v-radio-group',{attrs:{"disabled":!!_vm.answers[item.question.body.id] && _vm.showAnswers},model:{value:(_vm.answers[item.question.body.id]),callback:function ($$v) {_vm.$set(_vm.answers, item.question.body.id, $$v)},expression:"answers[item.question.body.id]"}},_vm._l((item.question.answers),function(answer,index){return _c('v-row',{key:index,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('course-module',_vm._b({attrs:{"border":_vm.answers[item.question.body.id] === answer.id && _vm.showAnswers
                    ? item.question.true == 'true'
                      ? '2px solid #0c0 !important'
                      : '2px solid #c00 !important'
                    : ''}},'course-module',{ title: answer.content, width: '100%' },false),[_c('div',{staticClass:"d-flex"},[(true)?_c('v-icon',{attrs:{"color":_vm.answers[item.question.body.id] === answer.id &&
                      _vm.showAnswers
                        ? item.question.true === 'true'
                          ? _vm.primaryColor
                          : _vm.RColor
                        : ''}},[_vm._v(" "+_vm._s(_vm.answers[item.question.body.id] === answer.id && _vm.showAnswers ? item.question.true === "true" ? _vm.answerIcon : _vm.closeIcon : "")+" ")]):_vm._e(),_c('v-radio',{attrs:{"color":"#00D4FF","value":answer.id}})],1)])],1)],1)}),1)],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }