<template>
  <v-col cols="12">
    <v-row>
      <div
        v-for="(item, index) in moduleQA"
        :key="index"
        class="w-100 text-right mt-10 pa-3"
      >
        <div class="text-grey question d-flex align-center text-lg-h6">
          <h4 class="mx-2">{{ index + 1 }}</h4>
          {{ item.question.body.content }}
        </div>
        <div class="d-flex flex-column w-100">
          <v-radio-group
            v-model="answers[item.question.body.id]"
            :disabled="!!answers[item.question.body.id] && showAnswers"
          >
            <!-- real -->
            <v-row
              v-for="(answer, index) in item.question.answers"
              :key="index"
              dense
            >
              <!-- answers container -->
              <v-col cols="12" md="6">
                <course-module
                  v-bind="{ title: answer.content, width: '100%' }"
                  :border="
                    answers[item.question.body.id] === answer.id && showAnswers
                      ? item.question.true == 'true'
                        ? '2px solid #0c0 !important'
                        : '2px solid #c00 !important'
                      : ''
                  "
                >
                  <!-- answers items -->
                  <div class="d-flex">
                    <v-icon
                      v-if="true"
                      :color="
                        answers[item.question.body.id] === answer.id &&
                        showAnswers
                          ? item.question.true === 'true'
                            ? primaryColor
                            : RColor
                          : ''
                      "
                    >
                      {{
                        answers[item.question.body.id] === answer.id &&
                        showAnswers
                          ? item.question.true === "true"
                            ? answerIcon
                            : closeIcon
                          : ""
                      }}
                    </v-icon>
                    <v-radio color="#00D4FF" :value="answer.id" />
                  </div>
                </course-module>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>
      </div>
    </v-row>
  </v-col>
</template>

<script>
import CourseModule from "./CourseModule";

export default {
  name: "QuestionsResults",

  components: {
    CourseModule
  },

  props: {
    moduleQA: Array,
    answers: Array,
    showAnswers: Boolean
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 300%;
  color: #efeeef;
  font-weight: bold;
}
</style>
